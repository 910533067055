"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const appConfig = {
    theme: {
        dark: false,
    },
    appName: 'LANA FAN CLUB',
    organizationId: 'i7skBsIuhgdw46sbnDxd',
    version: '1.0',
    ionicAppId: '',
    applicationKey: 'lana',
    applicationType: 'standalone',
    artistId: 'twjFc3Pum2MYqVzXEnlcMUBQ48i2',
    languages: ['ja', 'en'],
    ios: {
        bundleId: '',
        appId: '',
    },
    android: {
        bundleId: '',
    },
    platform: ['web'],
    custom: {
        modules: {
            tutorial: false,
            trade: false,
            membership: true,
            thread: false,
            prepaidPoint: null,
            gacha: false,
            nft: false,
            liveStream: false,
            item: true,
            store: false,
            campaign: false,
            mission: false,
            resale: true,
            preSale: true,
            event: true,
            cart: true,
            pickup: true,
            eprint: false,
            konbiniPayment: true,
        },
        tutorials: [
            'images/tutorial/Tutorial01.jpg',
            'images/tutorial/Tutorial02.jpg',
            'images/tutorial/Tutorial03.jpg',
            'images/tutorial/Tutorial04.jpg',
        ],
        globalTabs: [],
        artistTabs: ['home', 'postVideos', 'gallery', 'myProfile'],
    },
    deeplinkUrl: 'https://fc.lanal7.com',
    helpUrl: 'https://help.utoniq.com/ja/collections/2299305',
    storagePath: 'lana',
    dynamicLinkPrefix: 'lana',
    deeplinkProd: 'fc.lanal7.com',
};
exports.default = appConfig;
